import dynamic from 'next/dynamic';

const ConferenceTakeaways = dynamic(() => import('../../components/ArticleBlocks/ConferenceTakeaways'));
const ConferenceTopAdvisor = dynamic(() => import('../../components/ArticleBlocks/ConferenceTopAdvisor'));
const CurrentMemberInfoMini = dynamic(() => import('../../components/ArticleBlocks/CurrentMemberInfoMini'));
const FindAStudyGroup = dynamic(() => import('../../components/ArticleBlocks/FindAStudyGroup'));
const HomePageAccordion = dynamic(() => import('../../components/ArticleBlocks/HomePageAccordion'));
const MemberConnections = dynamic(() => import('../../components/ArticleBlocks/MemberConnections'));
const MyResourceFavorites = dynamic(() => import('../../components/ArticleBlocks/MyResourceFavorites'));
const MyStudyGroups = dynamic(() => import('../../components/ArticleBlocks/MyStudyGroups'));
const MyTraining = dynamic(() => import('../../components/ArticleBlocks/MyTraining'));
const MyTrendingDiscussions = dynamic(() => import('../../components/ArticleBlocks/MyTrendingDiscussions'));
const PageFooter = dynamic(() => import('../../components/ArticleBlocks/PageFooter'));
const PartnerLogos = dynamic(() => import('../../components/ArticleBlocks/PartnerLogos'));
const StudyGroupSearch = dynamic(() => import('../../components/ArticleBlocks/StudyGroupSearch'));
const ThreetiersPricing = dynamic(() => import('../../components/ArticleBlocks/ThreeTiersPricing'));
const TwoTiersPricing = dynamic(() => import('../../components/ArticleBlocks/TwoTiersPricing'));
const ConferenceBreakouts = dynamic(() => import('../../components/ArticleBlocks/ConferenceBreakouts'));
const SocialLinks = dynamic(() => import('../../components/ArticleBlocks/SocialLinks'));

export const PAGES_SLUG = {
    MEMBER_COMMUNITY_STUDY_GROUPS: 'members-community-study-groups',
    MEMBER_HOME: 'member-home',
    COMMUNITY_HOME: 'community-home',
    LEADER_TOOLS_HOME: 'leader-tools-home',
    ANNUAL_CONF: 'member-annual-conference',
    ANNUAL_CONF_WIFI: 'annual-conference-wifi',
    ANNUAL_CONF_PRAYER: 'annual-conference-prayer',
    ANNUAL_CONF_SURVEY: 'annual-conference-survey',
    ANNUAL_CONF_COMMUNITY: 'annual-conference-community',
    ANNUAL_CONF_NEXT: 'annual-conference-next',
    ANNUAL_CONF_MAP: 'annual-conference-maps',
    ANNUAL_CONF_MARRIOTT: 'annual-conference-marriott',
    ANNUAL_CONF_KNOW_BEFORE_YOU_GO: 'annual-conference-know-before-you-go',
    ANNUAL_CONF_GIVEAWAYS: 'annual-conference-giveaways',
    ANNUAL_CONF_EXHIBITORS: 'annual-conference-exhibitor',
    ANNUAL_CONF_EXHIBIT_HALL: 'annual-conference-exhibit-hall',
    ANNUAL_CONF_CE: 'annual-conference-ce',
    ANNUAL_CONF_AWARD: 'annual-conference-award',
    ANNUAL_CONF_TIPS: 'annual-conference-tips',
    ANNUAL_CONF_PRE_EVENTS: 'annual-conference-preconference-events',
    ANNUAL_CONF_JOB_FAIR: 'annual-conference-job-fair',
    ANNUAL_CONF_BECOME_CKA: 'annual-conference-become-a-cka',
    ANNUAL_CONF_GET_INVOLVED: 'annual-conference-get-involved',
    MARKETING_HOME: 'home',
    COURSES: 'courses',

    // marketing find a cka
    MARKETING_FIND_A_CKA: 'find-a-cka',
    CHOOSING_YOUR_CKA: 'choosing-your-cka',

    // marketing industry leader
    INDUSTRY_LEADER: 'industry-leader',
    LARGE_FIRMS: 'large-firms',
    UNIVERSITIES: 'universities',
    MINISTRIES: 'ministries',
    PRODUCTS_SERVICES: 'products-services',
    PROSPECTS_CLIENTS: 'prospects-clients',
    
    // marketing community
    MARKETING_COMMUNITY: 'community',
    MEMBERSHIP: 'membership',
    MARKETING_STUDY_GROUPS: 'study-groups',
    MARKETING_FIND_A_STUDY_GROUP: 'find-a-study-group',
    MARKETING_VIRTUAL_STUDY_GROUPS: 'virtual-study-groups',
    MARKETING_CURRENT_TOPIC: 'current-topic',

    // marketing events
    EVENTS: 'events',
    MARKETING_CONFERENCE: 'conference',
    MARKETING_CHAMPIONS_FORUM: 'champions-forum',
    MARKETING_VIRTUAL_EVENTS: 'virtual-events',
    MARKETING_CANADA_REGIONAL_EVENTS: 'canada-regional-events',

    // marketing get certified
    MARKETING_GET_CERTIFIED: 'get-certified',
    MARKETING_STEPS_TO_GET_CERTIFIED: 'steps-to-get-certified',
    MARKETING_STEPS_TO_GET_CERTIFIED_CANADA: 'steps-to-get-certified-canada',
    MARKETING_CKA_EDUCATIONAL_PROGRAM: 'cka-educational-program',
    MARKETING_CORE_TRAINING: 'core-training',
    MARKETING_CKA_TESTIMONIES: 'cka-testimonies',
    MARKETING_ETHICS_AND_ENFORCEMENT: 'ethics-and-enforcement',

    // marketing partners
    MARKETING_PARTNERS: 'partners',

    // marketing about
    MARKETING_ABOUT: 'about',

    // marketing privacy policy
    MARKETING_PRIVACY_POLICY: 'privacy-policy',

    // marketing cookie policy
    MARKETING_COOKIE_POLICY: 'cookie-policy',

    // marketing terms of use
    MARKETING_TERMS_OF_USE: 'terms-of-use',
    WOMENS_COMMUNITY_GROUP: 'womens-community-group',

    // firms
    FIRMS_LPL: 'firm-lpl',
    FIRMS_EJ: 'firm-ej',
    FIRMS_RJ: 'firm-rj',

    // faq
    FAQ: 'faq',
    // join
    JOIN: 'join',
    SETTINGS: 'settings',
    FORGOT_PASSOWORD: 'forgot-password',
    ANNUAL_CONF_RECORDINGS: 'annual-conference-recordings',

    // preview
    PREVIEW: 'preview',
};

export const ARTICLE_BLOCKS_TYPE = {
    HEADER_THREE_CARDS: 'headerThreeCards',
    RESOURCE_THREE_COLUMN: 'resourceThreeColumn',
    SIMPLE_DIVIDER: 'simpleDivider',
    IMAGE_WITH_DETAIL_OVERLAY: 'imageWithDetailOverlay',
    HEADER_IMAGE: 'headerImage',
    RICHTEXT: 'richText',
    HERO_ANGLED_IMAGE_ON_RIGHT: 'heroAngledImageOnRight',
    SIMPLE_LOGO_STRIP: 'simpleLogoStrip',
    FRONT_END_COMPONENT: 'frontEndComponent',
    TWO_COLUMN_VIDEO: 'twoColumnVideo',
    FULL_VIDEO: 'fullVideo',
    TWO_COLUMN_IMAGE: 'twoColumnImage',
    TWO_COLUMN_IMAGES: 'twoColumnImages',
    TWO_COLUMN_TEXT_GRID: 'twoColumnTextGrid',
    STAT_CARD: 'statCard',
    TESTIMONIAL_SLIDER: 'testimonialSlider',
    TESTIMONIAL_SPEAKER: 'testimonialSpeaker',
    SIMPLE_HEADER: 'simpleHeader',
    IMAGE_CAROUSEL: 'imageCarousel',
    ACCORDION: 'accordion',
    MEDIA_OBJECT: 'mediaObject',
    SIMPLE_BUTTON: 'simpleButton',
    IFRAME: 'iframe',
    BACKGROUND_IMAGE_HEADER: 'backgroundImageHeader',
    BACKGROUND_VIDEO_HEADER: 'backgroundVideoHeader',
    SPEAKER_CARDS: 'speakerCards',
    MODAL_BUTTON: 'modalButton',
    SPEAKER_ROUND_IMAGES_GRID: 'speakerRoundImagesGrid',
    TWO_TIERS_PRICING: 'twoTiersPricing',
    CONF_INFO_STRIP: 'conferenceInfoStrip',
    SIMPLE_STRING_CAROUSEL: 'simpleStringCarousel',
    CONFERENCE_BREAKOUTS: 'conferenceBreakouts',
    TWO_SIZE_IMAGE: 'twoSizeImage',
};

export const FRONT_END_COMPONENT_MAP = {
    'myStudyGroups': <MyStudyGroups />,
    'StudyGroupSearch': <StudyGroupSearch />,
    'memberConnections': <MemberConnections />,
    'myTrendingDiscussions': <MyTrendingDiscussions />,
    'myResourceFavorites': <MyResourceFavorites />,
    'myTraining': <MyTraining />,
    'homePageAccordion': <HomePageAccordion />,
    'pageFooter': <PageFooter />,
    'findAStudyGroup': <FindAStudyGroup />,
    'currentMemberInfoMini': <CurrentMemberInfoMini />,
    'twoTiersPricing': <TwoTiersPricing />,
    'partnerLogos': <PartnerLogos/>,
    'conferenceTakeaways': <ConferenceTakeaways />,
    'threeTiersPricing': <ThreetiersPricing />,
    'conferenceTopAdvisor': <ConferenceTopAdvisor />,
    'conferenceBreakouts': <ConferenceBreakouts />,
    'social': <SocialLinks />
};
